import React from 'react';

interface Props {
  className?: string;
}

const CrossIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    viewBox="0.12390102908773254 0.07476807499999999 12.93802107540772 12.850481743181424"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.42 12.62C-0.06 12.14 -0.06 11.37 0.42 10.89C1.47 9.84 9.83 1.48 10.87 0.43C11.37 -0.03 12.15 -0.01 12.61 0.49C13.03 0.94 13.06 1.63 12.67 2.11C11.62 3.16 3.21 11.57 2.16 12.62C1.92 12.83 1.61 12.93 1.29 12.92C0.98 12.94 0.66 12.83 0.42 12.62Z"
      fill="currentColor"
    />
    <path
      d="M10.87 12.56C9.83 11.52 1.47 3.15 0.42 2.11C-0.02 1.59 0.04 0.81 0.55 0.37C1.02 -0.02 1.7 -0.02 2.16 0.37C3.21 1.42 11.62 9.78 12.67 10.83C13.17 11.29 13.19 12.07 12.73 12.57C12.71 12.59 12.69 12.6 12.67 12.62C12.41 12.85 12.08 12.95 11.74 12.92C11.41 12.92 11.1 12.79 10.87 12.56Z"
      fill="currentColor"
    />
  </svg>
);

export default CrossIcon;
